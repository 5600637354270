import useAuthentication from './useAuthentication';
import { onAuthStateChanged } from '@firebase/auth';
import { auth } from 'Config/Firebase/FirebaseHelpers';
import { DELETE_SAVED_PRODUCT_RESET, SAVE_PRODUCT_RESET } from 'Store/Types';
import { getSavedProducts, getUserLogged } from 'Store/actions/UserActions';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const useAuthenticationRequests = () => {
  const dispatch = useDispatch();
  const { setAuthState, authState, handleLogout } = useAuthentication();
  const { user: userData, errorLogin } = useSelector(
    ({ userLogin }) => userLogin
  );
  const { successSaveProduct } = useSelector(
    ({ saveProducts }) => saveProducts
  );

  const { successDeleteSavedProduct } = useSelector(
    ({ deleteSaved }) => deleteSaved
  );
  const {
    successLoginGoogleOneTap,
    uid: googleOTLUid,
    token: googleOTLToken,
  } = useSelector(({ userGoogleOneTapLogin }) => userGoogleOneTapLogin);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        if (
          !(
            window.localStorage
              .getItem('access_token')
              ?.includes('googleusercontent') &&
            Boolean(window.localStorage.getItem('uid'))
          )
        ) {
          setAuthState({
            logged: true,
            uid: user.uid,
            accessToken: user?.accessToken,
          });
          window.localStorage.setItem('access_token', user?.accessToken);
        }
      } else {
        if (
          !(
            window.localStorage
              .getItem('access_token')
              ?.includes('googleusercontent') &&
            Boolean(window.localStorage.getItem('uid'))
          )
        ) {
          window.localStorage.removeItem('access_token');
          window.localStorage.removeItem('uid');
          setAuthState({
            logged: false,
            uid: '',
            accessToken: '',
          });
        }
      }
    });
  }, []);

  useEffect(() => {
    if (successLoginGoogleOneTap && googleOTLUid && googleOTLToken) {
      setAuthState({
        ...authState,
        logged: true,
        uid: googleOTLUid,
        accessToken: googleOTLToken,
      });
      window.localStorage.setItem('access_token', googleOTLToken);
      window.localStorage.setItem('uid', googleOTLUid);
    }
  }, [successLoginGoogleOneTap, googleOTLUid, googleOTLToken]);

  useEffect(() => {
    if (auth.currentUser?.uid) {
      dispatch(getUserLogged(auth.currentUser?.uid));
    } else if (
      window.localStorage
        .getItem('access_token')
        ?.includes('googleusercontent') &&
      Boolean(window.localStorage.getItem('uid'))
    ) {
      dispatch(getUserLogged(window.localStorage.getItem('uid') as string));
    }
  }, [auth, window.localStorage, authState]);

  useEffect(() => {
    if (successSaveProduct && authState.logged && userData?.uid) {
      dispatch(getSavedProducts(userData?.id));
    }
    if (successSaveProduct) {
      setTimeout(() => {
        dispatch({ type: SAVE_PRODUCT_RESET });
      }, 6000);
    }
  }, [successSaveProduct, userData]);

  useEffect(() => {
    if (successDeleteSavedProduct) {
      dispatch(getSavedProducts(userData?.id));
    }
    if (successDeleteSavedProduct) {
      setTimeout(() => {
        dispatch({ type: DELETE_SAVED_PRODUCT_RESET });
      }, 6000);
    }
  }, [successDeleteSavedProduct, userData]);

  useEffect(() => {
    if (errorLogin === 'USER_NOT_FOUND') {
      handleLogout();
    }
  }, [errorLogin]);

  useEffect(() => {
    if (
      window.localStorage
        .getItem('access_token')
        ?.includes('googleusercontent') &&
      Boolean(window.localStorage.getItem('uid'))
    ) {
      setAuthState({
        logged: true,
        uid: window.localStorage.getItem('uid') ?? '',
        accessToken: window.localStorage.getItem('access_token') ?? '',
      });
    }
  }, []);

  return;
};

export default useAuthenticationRequests;
