import { UserRegistrationData } from 'Components/LoginComponent/types/types';
import { logout, sendSessionData } from 'Config/Firebase/AuthHelpers';
import {
  GET_SAVED_PRODUCTS_RESET,
  LOGIN_GOOGLE_ONE_TAP_USER_RESET,
  LOGIN_GOOGLE_USER_RESET,
  LOGIN_USER_RESET,
} from 'Store/Types';
import { handleGoogleOneTapLogin } from 'Store/actions/UserActions';
import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

interface ReturnValue {
  authState: {
    logged: boolean;
    uid: string;
    accessToken: string;
  };
  user: UserRegistrationData | null;
  handleSendSessionDataTo: () => any;
  handleLogout: () => any;
  handleOneTapLogin: (email: string, name: string, token: string) => any;
  handleCheckIfInFavorites: (productId: string) => any;
  favorites: any[];
  setAuthState: React.Dispatch<
    React.SetStateAction<{
      logged: boolean;
      uid: string;
      accessToken: string;
    }>
  >;
  setFavoritesMapped: React.Dispatch<React.SetStateAction<any[]>>;
}

const useAuthentication = (): ReturnValue => {
  const dispatch = useDispatch();

  const [authState, setAuthState] = useState<{
    logged: boolean;
    uid: string;
    accessToken: string;
  }>({
    logged: window.localStorage.getItem('access_token') ? true : false,
    uid: '',
    accessToken: window.localStorage.getItem('access_token') ?? '',
  });
  const { user: userData } = useSelector(({ userLogin }) => userLogin);
  const { favorites } = useSelector(({ getSavedProducts }) => getSavedProducts);
  const handleSendSessionDataTo = useCallback(async () => {
    if (authState.logged && userData) {
      const result = await sendSessionData({
        uid: userData.uid,
        name: userData.name,
        token: authState.accessToken,
        lastConnection: new Date().getTime(),
      });

      return result;
    }
  }, [userData, authState]);

  const handleLogout = () => {
    if (
      window.localStorage
        .getItem('access_token')
        ?.includes('googleusercontent') &&
      Boolean(window.localStorage.getItem('uid'))
    ) {
      window.localStorage.removeItem('access_token');
      window.localStorage.removeItem('uid');
      dispatch({
        type: LOGIN_USER_RESET,
      });
      dispatch({
        type: LOGIN_GOOGLE_USER_RESET,
      });
      dispatch({
        type: LOGIN_GOOGLE_ONE_TAP_USER_RESET,
      });
      dispatch({
        type: GET_SAVED_PRODUCTS_RESET,
      });
      setAuthState({
        logged: false,
        uid: '',
        accessToken: '',
      });
      window.location.reload();
    } else {
      logout().then(() => {
        window.localStorage.removeItem('access_token');
        dispatch({
          type: LOGIN_USER_RESET,
        });
        dispatch({
          type: LOGIN_GOOGLE_USER_RESET,
        });
        dispatch({
          type: LOGIN_GOOGLE_ONE_TAP_USER_RESET,
        });
        dispatch({
          type: GET_SAVED_PRODUCTS_RESET,
        });
        setAuthState({
          logged: false,
          uid: '',
          accessToken: '',
        });
        window.location.reload();
      });
    }
  };

  const handleOneTapLogin = async (
    email: string,
    name: string,
    token: string
  ) => {
    dispatch(handleGoogleOneTapLogin(email, name, token));
  };

  const handleCheckIfInFavorites = useCallback(
    (productId: string) => {
      if (!favorites || favorites.length == 0) {
        return false;
      }

      return favorites.find((item) => item.productId === productId);
    },
    [favorites]
  );

  return {
    authState,
    user: userData,
    handleSendSessionDataTo,
    handleLogout,
    handleOneTapLogin,
    handleCheckIfInFavorites,
    favorites,
    setAuthState,
  };
};

export default useAuthentication;
