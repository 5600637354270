import useAuthentication from 'Hooks/useAuthentication';
import { HOME } from 'Routes/routes';
import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';

interface PrivateRouteProps {
  exact: boolean;
  path: string;
  component: React.FC | any;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({
  exact,
  path,
  component,
}) => {
  const { authState } = useAuthentication();
  const { user } = useSelector(({ userLogin }) => userLogin);

  if (
    user ||
    authState.accessToken ||
    window.localStorage.getItem('access_token')
  ) {
    return <Route exact={exact} path={path} component={component} />;
  }

  return <Redirect to={HOME} />;
};

export default PrivateRoute;
