import React from 'react';
//import SplashIcon from "Assets/images/splash.svg";
import SplashIcon from 'Assets/images/splashV2.gif';

export const Splash: React.FC = () => {
  return (
    <div
      style={{
        display: 'flex',
        width: '100vw',
        height: '100vh',
        justifyContent: 'center',
        alignItems: 'center',
        background: 'black',
      }}
    >
      <img style={{ width: '50%', height: '50%' }} src={SplashIcon} />
    </div>
  );
};
