import { createMuiTheme } from '@material-ui/core';
import { CreateCSSProperties } from '@material-ui/core/styles/withStyles';
import MontserratRegularTTF from '../Assets/fonts/Montserrat/Montserrat-Regular.ttf';
import MontserratLightTTF from '../Assets/fonts/Montserrat/Montserrat-Light.ttf';
import MontserratMediumTTF from '../Assets/fonts/Montserrat/Montserrat-Medium.ttf';
import MontserratBoldTTF from '../Assets/fonts/Montserrat/Montserrat-Bold.ttf';

const _buttonRoot: CreateCSSProperties = {
  borderRadius: '1px',
  textTransform: 'none',
  padding: '14px 26px',
  boxShadow: 'none',
};

const HelveticaNeueNormal = {
  fontFamily: "'Inter', sans-serif",
  fontStyle: 'normal',
  // fontDisplay: "swap",
  fontWeight: 400,
  src: `
      url(${MontserratRegularTTF}) format("truetype")
    `,
};

const HelveticaNeueLight = {
  fontFamily: "'Inter', sans-serif",
  fontStyle: 'normal',
  // fontDisplay: "swap",
  fontWeight: 300,
  src: `
      url(${MontserratLightTTF}) format("truetype")
    `,
};

const HelveticaNeueMedium = {
  fontFamily: "'Inter', sans-serif",
  fontStyle: 'normal',
  // fontDisplay: "swap",
  fontWeight: 500,
  src: `
      url(${MontserratMediumTTF}) format("truetype")
    `,
};

const HelveticaNeueBold = {
  fontFamily: "'Inter', sans-serif",
  fontStyle: 'normal',
  // fontDisplay: "swap",
  fontWeight: 700,
  src: `
      url(${MontserratBoldTTF}) format("truetype")
    `,
};

const _typography = {
  fontFamily: ["'Inter', sans-serif"].join(','),
  fontSize: 16,
  letterSpacing: '-0.04em',
  h1: {
    fontStyle: 'normal',
    fontSize: '56px',
    lineHeight: '1.2em',
    fontWeight: 700,
  },

  h2: {
    fontStyle: 'normal',
    fontSize: '50px',
    lineHeight: '1em',
    fontWeight: 700,
  },

  h3: {
    fontStyle: 'normal',
    fontSize: '44px',
    lineHeight: '1em',
    fontWeight: 700,
  },

  h4: {
    fontStyle: 'normal',
    fontSize: '40px',
    lineHeight: '1em',
    fontWeight: 700,
  },

  h5: {
    fontStyle: 'normal',
    fontSize: '30px',
    lineHeight: '39px',
    fontWeight: 700,
  },

  h6: {
    fontStyle: 'normal',
    fontSize: '28px',
    lineHeight: '30px',
    fontWeight: 300,
  },

  subtitle1: {
    fontStyle: 'normal',
    fontSize: '24px',
    lineHeight: '18px',
    fontWeight: 300,
  },

  subtitle2: {
    fontStyle: 'normal',
    fontSize: '18px',
    lineHeight: '18px',
    fontWeight: 700,
  },

  body1: {
    fontStyle: 'normal',
    fontSize: '18px',
    lineHeight: '24px',
    fontWeight: 400,
  },

  body2: {
    fontStyle: 'normal',
    fontSize: '16px',
    lineHeight: '16px',
    fontWeight: 500,
  },

  button: {
    fontStyle: 'normal',
    fontSize: '16px',
    lineHeight: '16px',
    fontWeight: 400,
    boxShadow: 'none',
  },

  caption: {
    fontStyle: 'normal',
    fontSize: '12px',
    lineHeight: '14px',
    fontWeight: 700,
  },

  overline: {
    fontStyle: 'normal',
    fontSize: '12px',
    lineHeight: '16px',
    fontWeight: 400,
  },

  customVariant: {
    fontStyle: 'normal',
    fontSize: '12px',
    lineHeight: '16px',
    fontWeight: 400,
  },
};

export const THEME = {
  shape: {
    borderRadius: 16,
  },
  palette: {
    primary: {
      main: '#042CB4',
      light: '#042CB4',
    },
    text: {
      secondary: 'black',
      orange: '#EB8A1C',
    },
    secondary: {
      main: '#FBD77B',
    },
    error: {
      main: '#C4161C',
    },
    success: {
      main: '#45B842',
    },
    background: {
      default: 'white',
      paper: 'white',
    },
    // text: {
    //   primary: "#393B3D",
    //   secondary: "#768692",
    //   disabled: "#C4C4C4",
    // },
  },
  typography: _typography,

  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [
          HelveticaNeueLight,
          HelveticaNeueNormal,
          HelveticaNeueMedium,
          HelveticaNeueBold,
        ],
      },
    },
    // Style sheet name ⚛️
    MuiButton: {
      // Name of the rule

      root: _buttonRoot,
      contained: {
        // Some CSS
        backgroundColor: '#F5F5F5',
        boxShadow: 'none',
      },
    },
    MuiFab: {
      root: {
        backgroundColor: '#FFF',
        borderRadius: '1px',
        boxShadow: 'none',
      },
    },
    MuiInput: {
      underline: {
        '&:before:not($error)': {
          borderBottom: '#979797 solid 1px',
        },
        '&:after:not($error)': {
          borderBottom: `2px solid #C4161C`,
        },
        '&:hover:not($disabled):not($focused):not($error):before': {
          borderBottom: `2px solid white`,
        },
        color: 'black',
      },
    },
  },
};

export const DefaultTheme = createMuiTheme({
  ...THEME,
});
