import AnimatedSwitch from 'Components/AnimatedSwitch/AnimatedSwitch';
import ScrollToTop from 'Components/ScrollToTop/ScrollToTop';
import { createBrowserHistory } from 'history';
import React from 'react';
import { Router } from 'react-router-dom';
import ReactGA from 'react-ga';

const history = createBrowserHistory();

// Initialize google analytics page view tracking
history.listen((location: any) => {
  ReactGA.set({ page: location.pathname }); // Update the user's current page
  ReactGA.pageview(location.pathname); // Record a pageview for the given page
});

const Routes: React.FC = () => {
  return (
    <Router history={history}>
      <ScrollToTop />
      <AnimatedSwitch />
    </Router>
  );
};

export default Routes;
