import { getCollection } from 'Config/Firebase/FirebaseHelpers';
import { AppThunk } from 'Store/ThunkType';
import {
  GET_PARTS_SUCCESS,
  GET_PARTS_REQUEST,
  GET_PARTS_FAILURE,
} from 'Store/Types';

export const getParts = (): AppThunk => {
  return (dispatch) => {
    dispatch({
      type: GET_PARTS_REQUEST,
    });
    getCollection('parts', [{ fieldName: 'name', direction: 'asc' }])
      .then((parts) => {
        dispatch({
          type: GET_PARTS_SUCCESS,
          payload: parts,
        });
      })
      .catch(() => {
        dispatch({
          type: GET_PARTS_FAILURE,
        });
      });
  };
};
