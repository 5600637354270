import {
  DISLIKE_BIKE_FAILURE,
  DISLIKE_BIKE_REQUEST,
  DISLIKE_BIKE_SUCCESS,
  GET_BIKES_FAILURE,
  GET_BIKES_REQUEST,
  GET_BIKES_SUCCESS,
  GET_BIKE_FAILURE,
  GET_BIKE_CLEAN,
  GET_BIKE_REQUEST,
  GET_BIKE_SUCCESS,
  LIKE_BIKE_FAILURE,
  LIKE_BIKE_REQUEST,
  LIKE_BIKE_SUCCESS,
} from 'Store/Types';

const initialState = {
  bikes: [],
  loading: false,
  likes: {},
  bike: {},
};

const BikeReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_BIKES_REQUEST:
      return {
        ...state,
        bikes: [],
        loading: true,
      };
    case GET_BIKES_SUCCESS:
      return {
        ...state,
        bikes: action.payload,
        loading: false,
      };
    case GET_BIKES_FAILURE:
      return {
        ...state,
        bikes: [],
        loading: false,
      };

    case GET_BIKE_REQUEST:
      return {
        ...state,
        bike: {},
        loading: true,
      };
    case GET_BIKE_SUCCESS:
      return {
        ...state,
        bike: action.payload,
        loading: false,
      };
    case GET_BIKE_FAILURE:
      return {
        ...state,
        bike: {},
        loading: false,
      };
    case GET_BIKE_CLEAN:
      return {
        ...state,
        bike: null,
        loading: false,
      };
    case LIKE_BIKE_REQUEST:
      return {
        ...state,
        likes: { ...state.likes, [action.payload]: true },
      };

    case LIKE_BIKE_SUCCESS:
      return {
        ...state,
      };

    case LIKE_BIKE_FAILURE:
      return {
        ...state,
        likes: { ...state.likes, [action.payload]: undefined },
      };

    case DISLIKE_BIKE_REQUEST:
      return {
        ...state,
        likes: { ...state.likes, [action.payload]: undefined },
      };

    case DISLIKE_BIKE_SUCCESS:
      return {
        ...state,
      };

    case DISLIKE_BIKE_FAILURE:
      return {
        ...state,
        likes: { ...state.likes, [action.payload]: true },
      };
    default:
      return state;
  }
};

export default BikeReducer;
