import {
    GET_SPARES_REQUEST,
    GET_SPARES_SUCCESS,
    GET_SPARES_FAILURE,
} from '../Types';

const initialState = {
    postLoading: false,
    getLoading: false,
    deleteLoading: false,
    spare: undefined,
    spareList: [],
    errors: {},
};

export const SpareReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_SPARES_REQUEST: {
            return {
                ...state,
                getLoading: true,
                spareList: [],
            };
        }
        case GET_SPARES_SUCCESS: {
            return {
                ...state,
                getLoading: false,
                spareList: action.payload.spareList,
                errors: { ...state.errors, spareList: undefined },
            };
        }
        case GET_SPARES_FAILURE: {
            return {
                ...state,
                getLoading: false,
                spareList: [],
                errors: { ...state.errors, spareList: action.payload.errors },
            };
        }
        default: {
            return state;
        }
    }
};
