import {
  getCollection,
  getDoc,
  updateDoc,
} from 'Config/Firebase/FirebaseHelpers';
import {
  DISLIKE_BICYCLE_FAILURE,
  DISLIKE_BICYCLE_REQUEST,
  DISLIKE_BICYCLE_SUCCESS,
  GET_BICYCLES_FAILURE,
  GET_BICYCLES_REQUEST,
  GET_BICYCLES_SUCCESS,
  GET_BICYCLE_FAILURE,
  BICYCLE_CLEAN,
  GET_BICYCLE_REQUEST,
  GET_BICYCLE_SUCCESS,
  LIKE_BICYCLE_FAILURE,
  LIKE_BICYCLE_REQUEST,
  LIKE_BICYCLE_SUCCESS,
} from 'Store/Types';

export const getBicycles = () => {
  return (dispatch) => {
    dispatch({
      type: GET_BICYCLES_REQUEST,
    });
    getCollection('bicycles')
      .then((bicycles) => {
        dispatch({
          type: GET_BICYCLES_SUCCESS,
          payload: bicycles,
        });
      })
      .catch(() => {
        dispatch({
          type: GET_BICYCLES_FAILURE,
        });
      });
  };
};

export const getBicycle = (id) => {
  return (dispatch) => {
    dispatch({
      type: GET_BICYCLE_REQUEST,
    });
    getDoc(`bicycles/${id}`)
      .then((bicycle) => {
        dispatch({
          type: GET_BICYCLE_SUCCESS,
          payload: bicycle,
        });
      })
      .catch(() => {
        dispatch({
          type: GET_BICYCLE_FAILURE,
        });
      });
  };
};

export const cleanBicycle = () => {
  return (dispatch) => {
    dispatch({
      type: BICYCLE_CLEAN,
    });
  };
};

export const likeBicycle = (bicycleId) => {
  return (dispatch) => {
    dispatch({
      type: LIKE_BICYCLE_REQUEST,
      payload: bicycleId,
    });
    getDoc(`bicycles/${bicycleId}`)
      .then((res) => {
        updateDoc(`bicycles/${bicycleId}`, {
          likes: res.likes ? res.likes + 1 : 1,
        });
      })
      .then(() => {
        dispatch({
          type: LIKE_BICYCLE_SUCCESS,
          payload: bicycleId,
        });
      })
      .catch(() => {
        dispatch({
          type: LIKE_BICYCLE_FAILURE,
          payload: bicycleId,
        });
      });
  };
};

export const dislikeBicycle = (bicycleId) => {
  return (dispatch) => {
    dispatch({
      type: DISLIKE_BICYCLE_REQUEST,
      payload: bicycleId,
    });
    getDoc(`bicycles/${bicycleId}`)
      .then((res) => {
        updateDoc(`bicycles/${bicycleId}`, {
          likes: res.likes ? res.likes - 1 : 0,
        });
      })
      .then(() => {
        dispatch({
          type: DISLIKE_BICYCLE_SUCCESS,
          payload: bicycleId,
        });
      })
      .catch(() => {
        dispatch({
          type: DISLIKE_BICYCLE_FAILURE,
          payload: bicycleId,
        });
      });
  };
};
