import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import createDebounce from 'redux-debounced';
import thunk from 'redux-thunk';

import BikeReducer from './reducers/BikeReducer';
import BicycleReducer from './reducers/BicycleReducer';
import DealerReducer from './reducers/DealerReducer';
import NewsletterReducer from './reducers/NewsletterReducer';
import PostReducer from './reducers/PostReducer';
import { SpareReducer } from './reducers/SpareReducer';
import { PartsReducer } from './reducers/PartsReducer';
import { ServiceProvReducer } from './reducers/ServiceProvReducer';
import BannersReducer from './reducers/BannersReducer';
import {
  CreateUserBikeReducer,
  DeleteSavedProduct,
  DeleteUserBikeReducer,
  ExistsUserReducer,
  GetSavedProductsReducer,
  RecoveryPasswordReducer,
  SaveAllyInfoReducer,
  SaveProductsReducer,
  UpdateUserBikeReducer,
  UserBikesReducer,
  UserGoogleLoginReducer,
  UserGoogleOneTapLoginReducer,
  UserLoginReducer,
  UserRegistrationReducer,
  UserUpdateReducer,
} from './reducers/UserReducers';
import { configsReducer } from './reducers/ConfigsReducer';

export const rootReducer = combineReducers({
  dealers: DealerReducer,
  bikes: BikeReducer,
  bicycles: BicycleReducer,
  posts: PostReducer,
  newsletter: NewsletterReducer,
  spares: SpareReducer,
  parts: PartsReducer,
  serviceProviders: ServiceProvReducer,
  banners: BannersReducer,
  userRegistration: UserRegistrationReducer,
  userUpdate: UserUpdateReducer,
  userLogin: UserLoginReducer,
  existsUser: ExistsUserReducer,
  recoveryPassword: RecoveryPasswordReducer,
  userLoginGoogle: UserGoogleLoginReducer,
  userBikes: UserBikesReducer,
  createUserBike: CreateUserBikeReducer,
  updateUserBike: UpdateUserBikeReducer,
  deleteUserBike: DeleteUserBikeReducer,
  userGoogleOneTapLogin: UserGoogleOneTapLoginReducer,
  saveProducts: SaveProductsReducer,
  getSavedProducts: GetSavedProductsReducer,
  deleteSaved: DeleteSavedProduct,
  saveAllyInfo: SaveAllyInfoReducer,
  configs: configsReducer,
});

const middlewares = [thunk, createDebounce()];

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  rootReducer,
  {},
  composeEnhancers(applyMiddleware(...middlewares))
);

export { store };
