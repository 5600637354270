type GoogleLatLng = google.maps.LatLng;
export const googleMapsApiKey = process.env.REACT_APP_GOOGLE_API_KEY as string;

export const loadMapApi = (): HTMLScriptElement => {
  const mapsURL = `https://maps.googleapis.com/maps/api/js?key=${googleMapsApiKey}&libraries=places,geometry&language=EN&region=NO&v=quarterly&`;
  const scripts = document.getElementsByTagName('script');

  for (let i = 0; i < scripts.length; i++) {
    if (scripts[i].src.indexOf(mapsURL) >= 0) return scripts[i];
  }

  const googleMapsScript = document.createElement('script');
  googleMapsScript.src = mapsURL;
  googleMapsScript.async = true;
  window.document.body.appendChild(googleMapsScript);

  return googleMapsScript;
};

export const directionApi = (
  origin: GoogleLatLng,
  destination: GoogleLatLng
): Promise<google.maps.DirectionsResult> => {
  return new Promise((resolve, reject) => {
    new google.maps.DirectionsService().route(
      {
        origin,
        destination,
        travelMode: google.maps.TravelMode.DRIVING,
      },
      (result: any, status) => {
        if (status === 'OK') {
          resolve(result);
        } else {
          reject(result);
        }
      }
    );
  });
};
