export const HOME = '/';
export const LOGIN = '/login';
export const MAP = '/map';
export const USER = '/user-page';
export const SPARE_PARTS = '/spare-parts';
export const BIKE_DETAIL = '/bike-detail';
export const OURS = '/ours'; // TODO: change old HISTORY to OURS
export const SOCIALPROGRAM = '/social-program';
export const BLOG = '/blog';
export const POSTVENTA = '/after-sale'; // TODO: separate in spare parts and warranty
export const TERMSCOND = '/terminoscondiciones'

// deprecated routes
export const GARAGE = '/garage';
export const FILTERED_GARAGE = '/sort';
export const FOOTER = '/map';
export const DEALER = '/become-a-dealer';
export const HISTORY = '/our-history';
