import { getCollection } from 'Config/Firebase/FirebaseHelpers';
import { AppThunk } from 'Store/ThunkType';
import {
  GET_SERVICE_PROV_REQUEST,
  GET_SERVICE_PROV_SUCCESS,
  GET_SERVICE_PROV_FAILURE,
} from 'Store/Types';

export const getServiceProviders = (): AppThunk => {
  return (dispatch) => {
    dispatch({
      type: GET_SERVICE_PROV_REQUEST,
    });
    getCollection('serviceprov', [{ fieldName: 'name', direction: 'asc' }])
      .then((serviceProviders) => {
        dispatch({
          type: GET_SERVICE_PROV_SUCCESS,
          payload: serviceProviders,
        });
      })
      .catch(() => {
        dispatch({
          type: GET_SERVICE_PROV_FAILURE,
        });
      });
  };
};
