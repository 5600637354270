import { NewsletterActionTypes } from 'Store/actions/types';
import {
  NEWSLETTER_SUBSCRIPTION_FAILURE,
  NEWSLETTER_SUBSCRIPTION_REQUEST,
  NEWSLETTER_SUBSCRIPTION_SUCCESS,
  NEWSLETTER_SUBSCRIPTION_RESET,
} from 'Store/Types';

type ActionStatus = 'NONE' | 'LOADING' | 'SUCCESS' | 'FAILURE';

interface NewsletterState {
  actionStatus: ActionStatus;
}
const initialState: NewsletterState = {
  actionStatus: 'NONE',
};

const NewsletterReducer = (
  state = initialState,
  action: NewsletterActionTypes
): NewsletterState => {
  switch (action.type) {
    case NEWSLETTER_SUBSCRIPTION_RESET:
      return {
        ...state,
        actionStatus: 'NONE',
      };
    case NEWSLETTER_SUBSCRIPTION_REQUEST:
      return {
        ...state,
        actionStatus: 'LOADING',
      };
    case NEWSLETTER_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        actionStatus: 'SUCCESS',
      };
    case NEWSLETTER_SUBSCRIPTION_FAILURE:
      return {
        ...state,
        actionStatus: 'FAILURE',
      };
    default:
      return state;
  }
};

export default NewsletterReducer;
