import { BlogPostActionTypes } from 'Store/actions/types';
import {
  GET_POST_FAILURE,
  GET_POST_REQUEST,
  GET_POST_SUCCESS,
  GET_POSTS_FAILURE,
  GET_POSTS_REQUEST,
  GET_POSTS_SUCCESS,
  GET_SUGGESTED_POSTS_FAILURE,
  GET_SUGGESTED_POSTS_REQUEST,
  GET_SUGGESTED_POSTS_SUCCESS,
} from 'Store/Types';
import { BlogPost } from 'types/blog';

interface BlogPostState {
  posts: BlogPost[];
  post: any;
  suggestedPosts: BlogPost[];
  loading: boolean;
  loadingPost: boolean;
  loadingSuggestedPosts: boolean;
}

const initialState: BlogPostState = {
  posts: [],
  post: {},
  suggestedPosts: [],
  loading: false,
  loadingPost: false,
  loadingSuggestedPosts: false,
};

const PostReducer = (
  state = initialState,
  action: BlogPostActionTypes
): BlogPostState => {
  switch (action.type) {
    case GET_POSTS_REQUEST:
      return {
        ...state,
        posts: [],
        loading: true,
      };
    case GET_POSTS_SUCCESS:
      return {
        ...state,
        posts: action.payload,
        loading: false,
      };
    case GET_POSTS_FAILURE:
      return {
        ...state,
        posts: [],
        loading: false,
      };

    case GET_POST_REQUEST:
      return {
        ...state,
        post: {},
        loadingPost: true,
      };
    case GET_POST_SUCCESS:
      return {
        ...state,
        post: action.payload,
        loadingPost: false,
      };
    case GET_POST_FAILURE:
      return {
        ...state,
        post: {},
        loadingPost: false,
      };

    case GET_SUGGESTED_POSTS_REQUEST:
      return {
        ...state,
        suggestedPosts: [],
        loadingSuggestedPosts: true,
      };
    case GET_SUGGESTED_POSTS_SUCCESS:
      return {
        ...state,
        suggestedPosts: action.payload,
        loadingSuggestedPosts: false,
      };
    case GET_SUGGESTED_POSTS_FAILURE:
      return {
        ...state,
        suggestedPosts: [],
        loadingSuggestedPosts: false,
      };

    default:
      return state;
  }
};

export default PostReducer;
