import {
  GET_BANNERS_REQUEST,
  GET_BANNERS_SUCCESS,
  GET_BANNERS_FAILURE,
} from 'Store/Types';

const initialState = {
  banners: [],
  loading: false,
};

const BannersReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_BANNERS_REQUEST:
      return {
        ...state,
        banners: [],
        loading: true,
      };
    case GET_BANNERS_SUCCESS:
      return {
        ...state,
        banners: action.payload,
        loading: false,
      };
    case GET_BANNERS_FAILURE:
      return {
        ...state,
        banners: [],
        loading: false,
      };
    default:
      return state;
  }
};

export default BannersReducer;
