import { DealersActionTypes } from 'Store/actions/types';
import {
  GET_DEALERS_FAILURE,
  GET_DEALERS_REQUEST,
  GET_DEALERS_SUCCESS,
  NEW_DEALER_FAILURE,
  NEW_DEALER_REQUEST,
  NEW_DEALER_RESET,
  NEW_DEALER_SUCCESS,
} from 'Store/Types';
import { Dealer } from 'types/dealers';

export type ActionStatus = 'NONE' | 'LOADING' | 'SUCCESS' | 'FAILURE';

export interface DealerState {
  dealers: Dealer[];
  loading: boolean;
  actionStatus: ActionStatus;
}

const initialState: DealerState = {
  dealers: [],
  loading: true,
  actionStatus: 'NONE',
};

const DealerReducer = (
  state = initialState,
  action: DealersActionTypes
): DealerState => {
  switch (action.type) {
    case GET_DEALERS_REQUEST:
      return {
        ...state,
        dealers: [],
        loading: true,
      };
    case GET_DEALERS_SUCCESS:
      return {
        ...state,
        dealers: action.payload,
        loading: false,
      };
    case GET_DEALERS_FAILURE:
      return {
        ...state,
        dealers: [],
        loading: false,
      };

    case NEW_DEALER_RESET:
      return {
        ...state,
        loading: false,
        actionStatus: 'NONE',
      };
    case NEW_DEALER_REQUEST:
      return {
        ...state,
        loading: true,
        actionStatus: 'LOADING',
      };
    case NEW_DEALER_SUCCESS:
      return {
        ...state,
        loading: false,
        actionStatus: 'SUCCESS',
      };
    case NEW_DEALER_FAILURE:
      return {
        ...state,
        loading: false,
        actionStatus: 'FAILURE',
      };
    default:
      return state;
  }
};

export default DealerReducer;
