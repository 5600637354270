export const GET_BIKES_REQUEST = '@@BIKES/GET_BIKES_REQUEST';
export const GET_BIKES_SUCCESS = '@@BIKES/GET_BIKES_SUCCESS';
export const GET_BIKES_FAILURE = '@@BIKES/GET_BIKES_FAILURE';

export const GET_BIKE_REQUEST = '@@BIKES/GET_BIKE_REQUEST';
export const GET_BIKE_SUCCESS = '@@BIKES/GET_BIKE_SUCCESS';
export const GET_BIKE_FAILURE = '@@BIKES/GET_BIKE_FAILURE';
export const GET_BIKE_CLEAN = '@@BIKES/GET_BIKE_CLEAN';

export const LIKE_BIKE_REQUEST = '@@BIKES/LIKE_BIKE_REQUEST';
export const LIKE_BIKE_SUCCESS = '@@BIKES/LIKE_BIKE_SUCCESS';
export const LIKE_BIKE_FAILURE = '@@BIKES/LIKE_BIKE_FAILURE';

export const DISLIKE_BIKE_REQUEST = '@@BIKES/DISLIKE_BIKE_REQUEST';
export const DISLIKE_BIKE_SUCCESS = '@@BIKES/DISLIKE_BIKE_SUCCESS';
export const DISLIKE_BIKE_FAILURE = '@@BIKES/DISLIKE_BIKE_FAILURE';

export const GET_DEALERS_REQUEST = '@@DEALERS/GET_DEALERS_REQUEST';
export const GET_DEALERS_SUCCESS = '@@DEALERS/GET_DEALERS_SUCCESS';
export const GET_DEALERS_FAILURE = '@@DEALERS/GET_DEALERS_FAILURE';

export const NEW_DEALER_RESET = '@@DEALERS/NEW_DEALER_RESET';
export const NEW_DEALER_REQUEST = '@@DEALERS/NEW_DEALER_REQUEST';
export const NEW_DEALER_SUCCESS = '@@DEALERS/NEW_DEALER_SUCCESS';
export const NEW_DEALER_FAILURE = '@@DEALERS/NEW_DEALER_FAILURE';

export const GET_POSTS_REQUEST = '@@POSTS/GET_POSTS_REQUEST';
export const GET_POSTS_SUCCESS = '@@POSTS/GET_POSTS_SUCCESS';
export const GET_POSTS_FAILURE = '@@POSTS/GET_POSTS_FAILURE';

export const GET_POST_REQUEST = '@@POSTS/GET_POST_REQUEST';
export const GET_POST_SUCCESS = '@@POSTS/GET_POST_SUCCESS';
export const GET_POST_FAILURE = '@@POSTS/GET_POST_FAILURE';

export const GET_BICYCLES_REQUEST = '@@BICYCLES/GET_BICYCLES_REQUEST';
export const GET_BICYCLES_SUCCESS = '@@BICYCLES/GET_BICYCLES_SUCCESS';
export const GET_BICYCLES_FAILURE = '@@BICYCLES/GET_BICYCLES_FAILURE';

export const GET_BICYCLE_REQUEST = '@@BICYCLES/GET_BICYCLE_REQUEST';
export const GET_BICYCLE_SUCCESS = '@@BICYCLES/GET_BICYCLE_SUCCESS';
export const GET_BICYCLE_FAILURE = '@@BICYCLES/GET_BICYCLE_FAILURE';
export const BICYCLE_CLEAN = '@@BICYCLES/GET_BICYCLE_CLEAN';

export const LIKE_BICYCLE_REQUEST = '@@BICYCLES/LIKE_BICYCLE_REQUEST';
export const LIKE_BICYCLE_SUCCESS = '@@BICYCLES/LIKE_BICYCLE_SUCCESS';
export const LIKE_BICYCLE_FAILURE = '@@BICYCLES/LIKE_BICYCLE_FAILURE';

export const DISLIKE_BICYCLE_REQUEST = '@@BICYCLES/DISLIKE_BICYCLE_REQUEST';
export const DISLIKE_BICYCLE_SUCCESS = '@@BICYCLES/DISLIKE_BICYCLE_SUCCESS';
export const DISLIKE_BICYCLE_FAILURE = '@@BICYCLES/DISLIKE_BICYCLE_FAILURE';

//Spare actions
export const GET_SPARES_REQUEST = '@@SPARES/GET_SPARES_REQUEST';
export const GET_SPARES_SUCCESS = '@@SPARES/GET_SPARES_SUCCESS';
export const GET_SPARES_FAILURE = '@@SPARES/GET_SPARES_FAILURE';

export const GET_PARTS_REQUEST = '@@PARTS/GET_PARTS_REQUEST';
export const GET_PARTS_SUCCESS = '@@PARTS/GET_PARTS_SUCCESS';
export const GET_PARTS_FAILURE = '@@PARTS/GET_PARTS_FAILURE';

export const GET_SERVICE_PROV_REQUEST = '@@PARTS/GET_SERVICE_PROV_REQUEST';
export const GET_SERVICE_PROV_SUCCESS = '@@PARTS/GET_SERVICE_PROV_SUCCESS';
export const GET_SERVICE_PROV_FAILURE = '@@PARTS/GET_SERVICE_PROV_FAILURE';

export const GET_SUGGESTED_POSTS_REQUEST =
  '@@POSTS/GET_SUGGESTED_POSTS_REQUEST';
export const GET_SUGGESTED_POSTS_SUCCESS =
  '@@POSTS/GET_SUGGESTED_POSTS_SUCCESS';
export const GET_SUGGESTED_POSTS_FAILURE =
  '@@POSTS/GET_SUGGESTED_POSTS_FAILURE';

export const NEWSLETTER_SUBSCRIPTION_RESET =
  '@@NEWSLETTER/NEWSLETTER_SUBSCRIPTION_RESET';
export const NEWSLETTER_SUBSCRIPTION_REQUEST =
  '@@NEWSLETTER/NEWSLETTER_SUBSCRIPTION_REQUEST';
export const NEWSLETTER_SUBSCRIPTION_SUCCESS =
  '@@NEWSLETTER/NEWSLETTER_SUBSCRIPTION_SUCCESS';
export const NEWSLETTER_SUBSCRIPTION_FAILURE =
  '@@NEWSLETTER/NEWSLETTER_SUBSCRIPTION_FAILURE';

export const GET_BANNERS_REQUEST = '@@BANNERS/GET_BANNERS_REQUEST';
export const GET_BANNERS_SUCCESS = '@@BANNERS/GET_BANNERS_SUCCESS';
export const GET_BANNERS_FAILURE = '@@BANNERS/GET_BANNERS_FAILURE';

export const REGISTER_USER_REQUEST = '@@USERS/REGISTER_USER_REQUEST';
export const REGISTER_USER_SUCCESS = '@@USERS/REGISTER_USER_SUCCESS';
export const REGISTER_USER_FAILURE = '@@USERS/REGISTER_USER_FAILURE';
export const REGISTER_USER_RESET = '@@USERS/REGISTER_USER_RESET';

export const UPDATE_USER_REQUEST = '@@USERS/UPDATE_USER_REQUEST';
export const UPDATE_USER_SUCCESS = '@@USERS/UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILURE = '@@USERS/UPDATE_USER_FAILURE';
export const UPDATE_USER_RESET = '@@USERS/UPDATE_USER_RESET';

export const LOGIN_USER_REQUEST = '@@USERS/LOGIN_USER_REQUEST';
export const LOGIN_USER_SUCCESS = '@@USERS/LOGIN_USER_SUCCESS';
export const LOGIN_USER_FAILURE = '@@USERS/LOGIN_USER_FAILURE';
export const LOGIN_USER_RESET = '@@USERS/LOGIN_USER_RESET';

export const LOGIN_GOOGLE_USER_REQUEST = '@@USERS/LOGIN_GOOGLE_USER_REQUEST';
export const LOGIN_GOOGLE_USER_SUCCESS = '@@USERS/LOGIN_GOOGLE_USER_SUCCESS';
export const LOGIN_GOOGLE_USER_FAILURE = '@@USERS/LOGIN_GOOGLE_USER_FAILURE';
export const LOGIN_GOOGLE_USER_RESET = '@@USERS/LOGIN_GOOGLE_USER_RESET';

export const LOGIN_GOOGLE_ONE_TAP_USER_REQUEST =
  '@@USERS/LOGIN_GOOGLE_ONE_TAP_USER_REQUEST';
export const LOGIN_GOOGLE_ONE_TAP_USER_SUCCESS =
  '@@USERS/LOGIN_GOOGLE_ONE_TAP_USER_SUCCESS';
export const LOGIN_GOOGLE_ONE_TAP_USER_FAILURE =
  '@@USERS/LOGIN_GOOGLE_ONE_TAP_USER_FAILURE';
export const LOGIN_GOOGLE_ONE_TAP_USER_RESET =
  '@@USERS/LOGIN_GOOGLE_ONE_TAP_USER_RESET';

export const EXISTS_USER_REQUEST = '@@USERS/EXISTS_USER_REQUEST';
export const EXISTS_USER_SUCCESS = '@@USERS/EXISTS_USER_SUCCESS';
export const EXISTS_USER_FAILURE = '@@USERS/EXISTS_USER_FAILURE';
export const EXISTS_USER_RESET = '@@USERS/EXISTS_USER_RESET';

export const RECOVERY_USER_REQUEST = '@@USERS/RECOVERY_USER_REQUEST';
export const RECOVERY_USER_SUCCESS = '@@USERS/RECOVERY_USER_SUCCESS';
export const RECOVERY_USER_FAILURE = '@@USERS/RECOVERY_USER_FAILURE';
export const RECOVERY_USER_RESET = '@@USERS/RECOVERY_USER_RESET';

export const GET_USER_BIKES_USER_REQUEST =
  '@@USERS/GET_USER_BIKES_USER_REQUEST';
export const GET_USER_BIKES_USER_SUCCESS =
  '@@USERS/GET_USER_BIKES_USER_SUCCESS';
export const GET_USER_BIKES_USER_FAILURE =
  '@@USERS/GET_USER_BIKES_USER_FAILURE';
export const GET_USER_BIKES_USER_RESET = '@@USERS/GET_USER_BIKES_USER_RESET';

export const CREATE_MY_BIKE_USER_REQUEST =
  '@@USERS/CREATE_MY_BIKE_USER_REQUEST';
export const CREATE_MY_BIKE_USER_SUCCESS =
  '@@USERS/CREATE_MY_BIKE_USER_SUCCESS';
export const CREATE_MY_BIKE_USER_FAILURE =
  '@@USERS/CREATE_MY_BIKE_USER_FAILURE';
export const CREATE_MY_BIKE_USER_RESET = '@@USERS/CREATE_MY_BIKE_USER_RESET';

export const UPDATE_MY_BIKE_USER_REQUEST =
  '@@USERS/UPDATE_MY_BIKE_USER_REQUEST';
export const UPDATE_MY_BIKE_USER_SUCCESS =
  '@@USERS/UPDATE_MY_BIKE_USER_SUCCESS';
export const UPDATE_MY_BIKE_USER_FAILURE =
  '@@USERS/UPDATE_MY_BIKE_USER_FAILURE';
export const UPDATE_MY_BIKE_USER_RESET = '@@USERS/UPDATE_MY_BIKE_USER_RESET';

export const DELETE_MY_BIKE_USER_REQUEST =
  '@@USERS/DELETE_MY_BIKE_USER_REQUEST';
export const DELETE_MY_BIKE_USER_SUCCESS =
  '@@USERS/DELETE_MY_BIKE_USER_SUCCESS';
export const DELETE_MY_BIKE_USER_FAILURE =
  '@@USERS/DELETE_MY_BIKE_USER_FAILURE';
export const DELETE_MY_BIKE_USER_RESET = '@@USERS/DELETE_MY_BIKE_USER_RESET';

export const GET_SAVED_PRODUCTS_REQUEST = '@@USERS/GET_SAVED_PRODUCTS_REQUEST';
export const GET_SAVED_PRODUCTS_SUCCESS = '@@USERS/GET_SAVED_PRODUCTS_SUCCESS';
export const GET_SAVED_PRODUCTS_FAILURE = '@@USERS/GET_SAVED_PRODUCTS_FAILURE';
export const GET_SAVED_PRODUCTS_RESET = '@@USERS/GET_SAVED_PRODUCTS_RESET';

export const SAVE_PRODUCT_REQUEST = '@@USERS/SAVE_PRODUCT_REQUEST';
export const SAVE_PRODUCT_SUCCESS = '@@USERS/SAVE_PRODUCT_SUCCESS';
export const SAVE_PRODUCT_FAILURE = '@@USERS/SAVE_PRODUCT_FAILURE';
export const SAVE_PRODUCT_RESET = '@@USERS/SAVE_PRODUCT_RESET';

export const DELETE_SAVED_PRODUCT_REQUEST =
  '@@USERS/DELETE_SAVED_PRODUCT_REQUEST';
export const DELETE_SAVED_PRODUCT_SUCCESS =
  '@@USERS/DELETE_SAVED_PRODUCT_SUCCESS';
export const DELETE_SAVED_PRODUCT_FAILURE =
  '@@USERS/DELETE_SAVED_PRODUCT_FAILURE';
export const DELETE_SAVED_PRODUCT_RESET = '@@USERS/DELETE_SAVED_PRODUCT_RESET';

export const SAVE_ALLY_INFO_REQUEST = '@@USERS/SAVE_ALLY_INFO_REQUEST';
export const SAVE_ALLY_INFO_SUCCESS = '@@USERS/SAVE_ALLY_INFO_SUCCESS';
export const SAVE_ALLY_INFO_FAILURE = '@@USERS/SAVE_ALLY_INFO_FAILURE';
export const SAVE_ALLY_INFO_RESET = '@@USERS/SAVE_ALLY_INFO_RESET';

export const GET_YOUTUBE_VIDEOS_REQUEST = '@@CONFIGS/GET_YOUTUBE_VIDEOS_REQUEST';
export const GET_YOUTUBE_VIDEOS_SUCCESS = '@@CONFIGS/GET_YOUTUBE_VIDEOS_SUCCESS';
export const GET_YOUTUBE_VIDEOS_FAILURE = '@@CONFIGS/GET_YOUTUBE_VIDEOS_FAILURE';