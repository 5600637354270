import {
  GET_YOUTUBE_VIDEOS_REQUEST,
  GET_YOUTUBE_VIDEOS_SUCCESS,
  GET_YOUTUBE_VIDEOS_FAILURE,
} from 'Store/Types';

const initialState = {
  getLoading: false,
  configs: {
    youtubeVideos: null
  },
  errors: null,
};

export const configsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_YOUTUBE_VIDEOS_REQUEST: {
      return {
        ...state,
        getLoading: true,
        configs: {
          youtubeVideos: null
        },
      };
    }
    case GET_YOUTUBE_VIDEOS_SUCCESS: {
      return {
        ...state,
        getLoading: false,
        configs: {
          youtubeVideos: action.payload
        },
        errors: null,
      };
    }
    case GET_YOUTUBE_VIDEOS_FAILURE: {
      return {
        ...state,
        getLoading: false,
        configs: {
          youtubeVideos: null
        },       
        errors: { ...state.errors },
      };
    }

    default: {
      return state;
    }
  }
};
