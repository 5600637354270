import {
  getCollection,
  getDoc,
  updateDoc,
} from 'Config/Firebase/FirebaseHelpers';
import {
  DISLIKE_BIKE_FAILURE,
  DISLIKE_BIKE_REQUEST,
  DISLIKE_BIKE_SUCCESS,
  GET_BIKES_FAILURE,
  GET_BIKES_REQUEST,
  GET_BIKES_SUCCESS,
  GET_BIKE_FAILURE,
  GET_BIKE_REQUEST,
  GET_BIKE_SUCCESS,
  GET_BIKE_CLEAN,
  LIKE_BIKE_FAILURE,
  LIKE_BIKE_REQUEST,
  LIKE_BIKE_SUCCESS,
} from 'Store/Types';

export const getBikes = (filter?: string) => {
  const filterField =
    filter === 'all'
      ? undefined
      : filter === 'most-wanted'
      ? 'likes'
      : filter === 'best-sold'
      ? 'sales'
      : filter === 'newest'
      ? undefined
      : filter === 'gas' || filter === 'electric'
      ? 'engine_power_type'
      : undefined;
  return (dispatch: any) => {
    dispatch({
      type: GET_BIKES_REQUEST,
    });
    getCollection('bikes', undefined, {
      fieldPath: 'category',
      opStr: '!=',
      value: 'bicycle',
    })
      .then((bikes: any) => {
        let newBikes = [];
        if (filterField === 'engine_power_type') {
          newBikes = bikes.filter(
            (bike: any) => bike?.engine_power_type === filter
          );
        }
        dispatch({
          type: GET_BIKES_SUCCESS,
          payload: newBikes.length > 0 ? newBikes : bikes,
        });
      })
      .catch(() => {
        dispatch({
          type: GET_BIKES_FAILURE,
        });
      });
  };
};

export const getBike = (id: string) => {
  return (dispatch: any) => {
    dispatch({
      type: GET_BIKE_REQUEST,
    });
    getDoc(`bikes/${id}`)
      .then((bike) => {
        dispatch({
          type: GET_BIKE_SUCCESS,
          payload: bike,
        });
      })
      .catch(() => {
        dispatch({
          type: GET_BIKE_FAILURE,
        });
      });
  };
};

export const cleanBike = () => {
  return (dispatch: any) => {
    dispatch({
      type: GET_BIKE_CLEAN,
    });
  };
};

export const likeBike = (bikeId: string) => {
  return (dispatch: any) => {
    dispatch({
      type: LIKE_BIKE_REQUEST,
      payload: bikeId,
    });
    getDoc(`bikes/${bikeId}`)
      .then((res: any) => {
        updateDoc(`bikes/${bikeId}`, {
          likes: res && res.likes ? res.likes + 1 : 1,
        });
      })
      .then(() => {
        dispatch({
          type: LIKE_BIKE_SUCCESS,
          payload: bikeId,
        });
      })
      .catch(() => {
        dispatch({
          type: LIKE_BIKE_FAILURE,
          payload: bikeId,
        });
      });
  };
};

export const dislikeBike = (bikeId: string) => {
  return (dispatch: any) => {
    dispatch({
      type: DISLIKE_BIKE_REQUEST,
      payload: bikeId,
    });
    getDoc(`bikes/${bikeId}`)
      .then((res: any) => {
        updateDoc(`bikes/${bikeId}`, {
          likes: res && res.likes ? res.likes - 1 : 0,
        });
      })
      .then(() => {
        dispatch({
          type: DISLIKE_BIKE_SUCCESS,
          payload: bikeId,
        });
      })
      .catch(() => {
        dispatch({
          type: DISLIKE_BIKE_FAILURE,
          payload: bikeId,
        });
      });
  };
};
