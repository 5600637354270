import {
  DISLIKE_BICYCLE_FAILURE,
  DISLIKE_BICYCLE_REQUEST,
  DISLIKE_BICYCLE_SUCCESS,
  GET_BICYCLES_FAILURE,
  GET_BICYCLES_REQUEST,
  GET_BICYCLES_SUCCESS,
  GET_BICYCLE_FAILURE,
  BICYCLE_CLEAN,
  GET_BICYCLE_REQUEST,
  GET_BICYCLE_SUCCESS,
  LIKE_BICYCLE_FAILURE,
  LIKE_BICYCLE_REQUEST,
  LIKE_BICYCLE_SUCCESS,
} from 'Store/Types';

const initialState = {
  bicycles: [],
  loading: false,
  likes: {},
  bicycle: {},
};

const BicycleReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_BICYCLES_REQUEST:
      return {
        ...state,
        bicycles: [],
        loading: true,
      };
    case GET_BICYCLES_SUCCESS:
      return {
        ...state,
        bicycles: action.payload,
        loading: false,
      };
    case GET_BICYCLES_FAILURE:
      return {
        ...state,
        bicycles: [],
        loading: false,
      };

    case GET_BICYCLE_REQUEST:
      return {
        ...state,
        bicycle: {},
        loading: true,
      };
    case GET_BICYCLE_SUCCESS:
      return {
        ...state,
        bicycle: action.payload,
        loading: false,
      };
    case GET_BICYCLE_FAILURE:
      return {
        ...state,
        bicycle: {},
        loading: false,
      };
    case BICYCLE_CLEAN:
      return {
        ...state,
        bicycle: null,
        loading: false,
      };
    case LIKE_BICYCLE_REQUEST:
      return {
        ...state,
        likes: { ...state.likes, [action.payload]: true },
      };

    case LIKE_BICYCLE_SUCCESS:
      return {
        ...state,
      };

    case LIKE_BICYCLE_FAILURE:
      return {
        ...state,
        likes: { ...state.likes, [action.payload]: undefined },
      };

    case DISLIKE_BICYCLE_REQUEST:
      return {
        ...state,
        likes: { ...state.likes, [action.payload]: undefined },
      };

    case DISLIKE_BICYCLE_SUCCESS:
      return {
        ...state,
      };

    case DISLIKE_BICYCLE_FAILURE:
      return {
        ...state,
        likes: { ...state.likes, [action.payload]: true },
      };
    default:
      return state;
  }
};

export default BicycleReducer;
