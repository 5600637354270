import {
  GET_PARTS_REQUEST,
  GET_PARTS_SUCCESS,
  GET_PARTS_FAILURE,
} from '../Types';

const initialState = {
  getLoading: false,
  parts: [],
  errors: undefined,
};

export const PartsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PARTS_REQUEST: {
      return {
        ...state,
        getLoading: true,
        parts: [],
      };
    }
    case GET_PARTS_SUCCESS: {
      return {
        ...state,
        getLoading: false,
        parts: action.payload,
        errors: undefined,
      };
    }
    case GET_PARTS_FAILURE: {
      return {
        ...state,
        getLoading: false,
        parts: [],
        errors: { ...state.errors, parts: action.payload.errors },
      };
    }
    default: {
      return state;
    }
  }
};
