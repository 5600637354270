import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import {
  getFirestore,
  collection,
  addDoc,
  doc,
  getDoc as getDocFirestore,
  getDocs,
  updateDoc as updateDocFirestore,
  deleteDoc as deleteDocFirestore,
  query,
  orderBy,
  where,
} from 'firebase/firestore';
import {
  getStorage,
  ref,
  uploadString,
  getDownloadURL,
} from 'firebase/storage';
import { firebaseConfig } from 'Config/Firebase/Config';
import { getAuth } from 'firebase/auth';

const app = initializeApp(firebaseConfig);

const db = getFirestore(app);
const storage = getStorage(app);
getAnalytics(app);

export const auth = getAuth(app);

export const createDoc = async (path, data) => {
  try {
    const docRef = await addDoc(collection(db, path), data);
    return { ...data, id: docRef.id };
  } catch (err) {
    return { error: err, data: undefined, id: undefined };
  }
};

export const getDoc = async (path) => {
  try {
    const docSnap = await getDocFirestore(doc(db, path));
    if (docSnap.exists()) {
      return { ...docSnap.data(), id: docSnap.id };
    } else {
      return undefined;
    }
  } catch (err) {
    return { error: err, data: undefined, id: undefined };
  }
};

export const existsDoc = async (path) => {
  try {
    const docSnap = await getDocFirestore(doc(db, path));
    if (docSnap.exists()) {
      return true;
    } else {
      return false;
    }
  } catch (err) {
    return false;
  }
};

export const getCollection = async (
  path,
  orderParams = [],
  whereParams = {
    fieldPath: '',
    opStr: '',
    value: '',
  }
) => {
  try {
    let q = collection(db, path);

    if (orderParams.length)
      orderParams.forEach((op) => {
        q = query(
          q,
          orderBy(op.fieldName, op.direction ? op.direction : 'asc')
        );
      });

    if (Object.values(whereParams).filter(Boolean).length) {
      const { fieldPath, opStr, value } = whereParams;
      q = query(q, where(fieldPath, opStr, value));
    }

    const querySnapshot = await getDocs(q);
    const docs = [];
    querySnapshot.forEach((doc) => {
      docs.push({ ...doc.data(), id: doc.id });
    });
    return docs;
  } catch (err) {
    return { error: err, data: undefined, id: undefined };
  }
};

export const updateDoc = (path, data) => {
  return updateDocFirestore(doc(db, path), data);
};

export const deleteDoc = async (path) => {
  try {
    const docSnap = await getDocFirestore(doc(db, path));
    if (docSnap.exists()) {
      const data = { ...docSnap.data(), id: docSnap.id };
      await deleteDocFirestore(doc(db, path));
      return data;
    } else {
      return {
        error: 'The document does not exist',
        data: undefined,
        id: undefined,
      };
    }
  } catch (err) {
    return { error: err.message };
  }
};

export const convertImageToBase64 = (blob) => {
  const reader = new FileReader();
  reader.readAsDataURL(blob);
  return new Promise((resolve) => {
    reader.onloadend = () => {
      resolve(reader.result);
    };
  });
};

export const uploadImage = async (base64, path) => {
  try {
    const metadata = { contentType: 'image/jpeg' };
    const storageRef = ref(storage);
    const imageRef = ref(storageRef, path);

    await uploadString(imageRef, base64, 'data_url', metadata);

    const url = await getDownloadURL(imageRef);

    return { url, photo: base64 };
  } catch (err) {
    return { error: err.message };
  }
};
