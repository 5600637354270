import {
  EmailAuthProvider,
  browserSessionPersistence,
  createUserWithEmailAndPassword,
  reauthenticateWithCredential,
  sendPasswordResetEmail,
  setPersistence,
  signInWithCredential,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  updatePassword,
} from '@firebase/auth';
import { auth, createDoc } from './FirebaseHelpers';
import { GoogleAuthProvider } from 'firebase/auth';

const googleProvider = new GoogleAuthProvider();

export const loginWithGmail = () =>
  new Promise((res, rej) => {
    setPersistence(auth, browserSessionPersistence);
    signInWithPopup(auth, googleProvider)
      .then((result) => {
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        const user = result.user;
        res({
          credential,
          token,
          user,
        });
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        const email = error.customData.email;
        const credential = GoogleAuthProvider.credentialFromError(error);
        rej({
          errorCode,
          errorMessage,
          email,
          credential,
        });
      });
  });

export const loginWithGOTL = (cred) =>
  new Promise((res, rej) => {
    setPersistence(auth, browserSessionPersistence);
    signInWithCredential(auth, cred)
      .then((result) => {
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        const user = result.user;
        res({
          credential,
          token,
          user,
        });
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        const email = error.customData.email;
        const credential = GoogleAuthProvider.credentialFromError(error);
        rej({
          errorCode,
          errorMessage,
          email,
          credential,
        });
      });
  });

export const registerUserEmail = async (email, password) => {
  await setPersistence(auth, browserSessionPersistence);
  return createUserWithEmailAndPassword(auth, email, password);
};

export const loginUserWithEmail = async (email, password) => {
  await setPersistence(auth, browserSessionPersistence);
  return signInWithEmailAndPassword(auth, email, password);
};

export const sendRecoveryEmail = async (email) => {
  return sendPasswordResetEmail(auth, email);
};

export const logout = async () => {
  return signOut(auth);
};

export const sendSessionData = async (sessionData) => {
  const createdDoc = createDoc('user-sessions', sessionData);

  return createdDoc;
};

export const updateUserPassword = async (currentPassword, newPassword) => {
  const credentials = EmailAuthProvider.credential(
    auth.currentUser.email,
    currentPassword
  );

  const reauth = await reauthenticateWithCredential(
    auth.currentUser,
    credentials
  );

  const updatedPassword = await updatePassword(auth.currentUser, newPassword);

  return {
    reauth,
    updatedPassword,
  };
};
