import { createDoc, getCollection } from 'Config/Firebase/FirebaseHelpers';
import { AppThunk } from 'Store/ThunkType';
import {
  GET_DEALERS_FAILURE,
  GET_DEALERS_REQUEST,
  GET_DEALERS_SUCCESS,
  NEW_DEALER_FAILURE,
  NEW_DEALER_REQUEST,
  NEW_DEALER_RESET,
  NEW_DEALER_SUCCESS,
} from 'Store/Types';
import { NewDealer } from 'types/dealers';

export const getDealers = (): AppThunk => {
  return (dispatch) => {
    dispatch({
      type: GET_DEALERS_REQUEST,
    });
    getCollection('dealers', [{ fieldName: 'name', direction: 'asc' }])
      .then((dealers) => {
        dispatch({
          type: GET_DEALERS_SUCCESS,
          payload: dealers,
        });
      })
      .catch(() => {
        dispatch({
          type: GET_DEALERS_FAILURE,
        });
      });
  };
};

export const resetDealer = (): AppThunk => {
  return (dispatch) => {
    dispatch({
      type: NEW_DEALER_RESET,
    });
  };
};

export const newDealer = (data: NewDealer): AppThunk => {
  return (dispatch) => {
    dispatch({
      type: NEW_DEALER_REQUEST,
    });
    createDoc('dealer-requests', {
      ...data,
    })
      .then(() => {
        dispatch({
          type: NEW_DEALER_SUCCESS,
        });
      })
      .catch(() => {
        dispatch({
          type: NEW_DEALER_FAILURE,
        });
      });
  };
};
