import {
  GET_SERVICE_PROV_REQUEST,
  GET_SERVICE_PROV_SUCCESS,
  GET_SERVICE_PROV_FAILURE,
} from '../Types';

const initialState = {
  getLoading: false,
  serviceProviders: [],
  errors: undefined,
};

export const ServiceProvReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SERVICE_PROV_REQUEST: {
      return {
        ...state,
        getLoading: true,
        serviceProviders: [],
      };
    }
    case GET_SERVICE_PROV_SUCCESS: {
      return {
        ...state,
        getLoading: false,
        serviceProviders: action.payload,
        errors: undefined,
      };
    }
    case GET_SERVICE_PROV_FAILURE: {
      return {
        ...state,
        getLoading: false,
        serviceProviders: [],
        errors: action.payload.errors,
      };
    }
    default: {
      return state;
    }
  }
};
