import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import Routes from 'Routes';
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import { DefaultTheme } from 'Constants/themeConstants';
import { store } from 'Store/RootReducer';
import { Provider } from 'react-redux';
import './i18n.js';
import { Wrapper } from '@googlemaps/react-wrapper';
import ReactGA from 'react-ga';
import { Splash } from 'Components/Splash/Splash';
import { googleMapsApiKey } from 'Components/Maps/LoadMapApi';

const trackingId = 'UA-186843469-1';
ReactGA.initialize(trackingId);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Wrapper apiKey={googleMapsApiKey} version="beta" libraries={['marker']}>
        <Suspense fallback={<Splash />}>
          <ThemeProvider theme={DefaultTheme}>
            <CssBaseline></CssBaseline>
            <Routes />
          </ThemeProvider>
        </Suspense>
      </Wrapper>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
