import {
  REGISTER_USER_REQUEST,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_FAILURE,
  LOGIN_USER_REQUEST,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAILURE,
  EXISTS_USER_REQUEST,
  EXISTS_USER_SUCCESS,
  EXISTS_USER_FAILURE,
  RECOVERY_USER_REQUEST,
  RECOVERY_USER_SUCCESS,
  RECOVERY_USER_FAILURE,
  RECOVERY_USER_RESET,
  EXISTS_USER_RESET,
  LOGIN_USER_RESET,
  REGISTER_USER_RESET,
  LOGIN_GOOGLE_USER_REQUEST,
  LOGIN_GOOGLE_USER_SUCCESS,
  LOGIN_GOOGLE_USER_FAILURE,
  LOGIN_GOOGLE_USER_RESET,
  GET_USER_BIKES_USER_REQUEST,
  GET_USER_BIKES_USER_SUCCESS,
  GET_USER_BIKES_USER_FAILURE,
  GET_USER_BIKES_USER_RESET,
  CREATE_MY_BIKE_USER_FAILURE,
  CREATE_MY_BIKE_USER_REQUEST,
  CREATE_MY_BIKE_USER_SUCCESS,
  CREATE_MY_BIKE_USER_RESET,
  UPDATE_MY_BIKE_USER_REQUEST,
  UPDATE_MY_BIKE_USER_SUCCESS,
  UPDATE_MY_BIKE_USER_FAILURE,
  UPDATE_MY_BIKE_USER_RESET,
  DELETE_MY_BIKE_USER_REQUEST,
  DELETE_MY_BIKE_USER_SUCCESS,
  DELETE_MY_BIKE_USER_FAILURE,
  DELETE_MY_BIKE_USER_RESET,
  LOGIN_GOOGLE_ONE_TAP_USER_REQUEST,
  LOGIN_GOOGLE_ONE_TAP_USER_SUCCESS,
  LOGIN_GOOGLE_ONE_TAP_USER_FAILURE,
  LOGIN_GOOGLE_ONE_TAP_USER_RESET,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILURE,
  UPDATE_USER_RESET,
  SAVE_PRODUCT_REQUEST,
  SAVE_PRODUCT_SUCCESS,
  SAVE_PRODUCT_FAILURE,
  SAVE_PRODUCT_RESET,
  GET_SAVED_PRODUCTS_REQUEST,
  GET_SAVED_PRODUCTS_SUCCESS,
  GET_SAVED_PRODUCTS_FAILURE,
  GET_SAVED_PRODUCTS_RESET,
  DELETE_SAVED_PRODUCT_REQUEST,
  DELETE_SAVED_PRODUCT_SUCCESS,
  DELETE_SAVED_PRODUCT_FAILURE,
  DELETE_SAVED_PRODUCT_RESET,
  SAVE_ALLY_INFO_REQUEST,
  SAVE_ALLY_INFO_SUCCESS,
  SAVE_ALLY_INFO_FAILURE,
  SAVE_ALLY_INFO_RESET,
} from 'Store/Types';

export const UserRegistrationReducer = (
  state = { loading: false, success: false, error: null },
  action: any
) => {
  switch (action.type) {
    case REGISTER_USER_REQUEST:
      return { ...state, loading: true };
    case REGISTER_USER_SUCCESS:
      return { loading: false, success: true, error: null };
    case REGISTER_USER_FAILURE:
      return { loading: false, success: false, error: action.payload };
    case REGISTER_USER_RESET:
      return { loading: false, success: false, error: null };
    default: {
      return state;
    }
  }
};

export const UserUpdateReducer = (
  state = { loading: false, success: false, error: null },
  action: any
) => {
  switch (action.type) {
    case UPDATE_USER_REQUEST:
      return { ...state, loading: true };
    case UPDATE_USER_SUCCESS:
      return { loading: false, success: true, error: null };
    case UPDATE_USER_FAILURE:
      return { loading: false, success: false, error: action.payload };
    case UPDATE_USER_RESET:
      return { loading: false, success: false, error: null };
    default: {
      return state;
    }
  }
};

export const UserLoginReducer = (
  state = {
    loadingLogin: false,
    successLogin: false,
    user: null,
    errorLogin: null,
  },
  action: any
) => {
  switch (action.type) {
    case LOGIN_USER_REQUEST:
      return { ...state, loadingLogin: true };
    case LOGIN_USER_SUCCESS:
      return {
        loadingLogin: false,
        successLogin: true,
        user: action.payload,
        errorLogin: null,
      };
    case LOGIN_USER_FAILURE:
      return {
        loadingLogin: false,
        successLogin: false,
        errorLogin: action.payload,
      };
    case LOGIN_USER_RESET:
      return {
        loadingLogin: false,
        successLogin: false,
        user: null,
        errorLogin: null,
      };
    default: {
      return state;
    }
  }
};

export const UserGoogleLoginReducer = (
  state = {
    loadingLoginGoogle: false,
    successLoginGoogle: false,
    errorLoginGoogle: null,
  },
  action: any
) => {
  switch (action.type) {
    case LOGIN_GOOGLE_USER_REQUEST:
      return { ...state, loadingLogin: true };
    case LOGIN_GOOGLE_USER_SUCCESS:
      return {
        loadingLoginGoogle: false,
        successLoginGoogle: true,
        errorLoginGoogle: null,
      };
    case LOGIN_GOOGLE_USER_FAILURE:
      return {
        loadingLoginGoogle: false,
        successLoginGoogle: false,
        errorLoginGoogle: action.payload,
      };
    case LOGIN_GOOGLE_USER_RESET:
      return {
        loadingLoginGoogle: false,
        successLoginGoogle: false,
        user: null,
        errorLoginGoogle: null,
      };
    default: {
      return state;
    }
  }
};

export const UserGoogleOneTapLoginReducer = (
  state = {
    loadingLoginGoogleOneTap: false,
    successLoginGoogleOneTap: false,
    errorLoginGoogleOneTap: null,
  },
  action: any
) => {
  switch (action.type) {
    case LOGIN_GOOGLE_ONE_TAP_USER_REQUEST:
      return { ...state, loadingLoginGoogleOneTap: true };
    case LOGIN_GOOGLE_ONE_TAP_USER_SUCCESS:
      return {
        loadingLoginGoogleOneTap: false,
        successLoginGoogleOneTap: true,
        uid: action.payload.uid,
        token: action.payload.token,
        errorLoginGoogleOneTap: null,
      };
    case LOGIN_GOOGLE_ONE_TAP_USER_FAILURE:
      return {
        loadingLoginGoogleOneTap: false,
        successLoginGoogleOneTap: false,
        errorLoginGoogleOneTap: action.payload,
      };
    case LOGIN_GOOGLE_ONE_TAP_USER_RESET:
      return {
        loadingLoginGoogleOneTap: false,
        successLoginGoogleOneTap: false,
        userGoogleOneTap: null,
        errorLoginGoogleOneTap: null,
      };
    default: {
      return state;
    }
  }
};

export const ExistsUserReducer = (
  state = {
    loadingExistsUser: false,
    successExistsUser: false,
    exists: false,
    errorExistsUser: null,
  },
  action: any
) => {
  switch (action.type) {
    case EXISTS_USER_REQUEST:
      return { ...state, loadingExistsUser: true };
    case EXISTS_USER_SUCCESS:
      return {
        loadingExistsUser: false,
        successExistsUser: true,
        exists: action.payload,
        errorExistsUser: null,
      };
    case EXISTS_USER_FAILURE:
      return {
        loadingExistsUser: false,
        exists: false,
        errorExistsUser: action.payload,
      };
    case EXISTS_USER_RESET:
      return {
        loadingExistsUser: false,
        successExistsUser: false,
        exists: false,
        errorExistsUser: null,
      };
    default: {
      return state;
    }
  }
};

export const RecoveryPasswordReducer = (
  state = {
    loadingRecoveryUser: false,
    successRecoveryUser: false,
    errorRecoveryUser: null,
  },
  action: any
) => {
  switch (action.type) {
    case RECOVERY_USER_REQUEST:
      return { ...state, loadingRecoveryUser: true };
    case RECOVERY_USER_SUCCESS:
      return {
        loadingRecoveryUser: false,
        successRecoveryUser: true,
        errorRecoveryUser: null,
      };
    case RECOVERY_USER_FAILURE:
      return {
        loadingRecoveryUser: false,
        exists: false,
        errorRecoveryUser: action.payload,
      };
    case RECOVERY_USER_RESET:
      return {
        loadingRecoveryUser: false,
        successRecoveryUser: false,
        errorRecoveryUser: null,
      };
    default: {
      return state;
    }
  }
};

export const UserBikesReducer = (
  state = {
    loadingBikes: false,
    successBikes: false,
    bikes: [],
    errorBikes: null,
  },
  action: any
) => {
  switch (action.type) {
    case GET_USER_BIKES_USER_REQUEST:
      return { ...state, loadingBikes: true };
    case GET_USER_BIKES_USER_SUCCESS:
      return {
        loadingBikes: false,
        successBikes: true,
        bikes: action.payload,
        errorBikes: null,
      };
    case GET_USER_BIKES_USER_FAILURE:
      return {
        loadingBikes: false,
        successBikes: false,
        bikes: [],
        errorBikes: action.payload,
      };
    case GET_USER_BIKES_USER_RESET:
      return {
        loadingBikes: false,
        bikes: [],
        successBikes: false,
        errorBikes: null,
      };
    default: {
      return state;
    }
  }
};

export const CreateUserBikeReducer = (
  state = {
    loadingCreateBike: false,
    successCreateBike: false,
    errorCreateBikes: null,
  },
  action: any
) => {
  switch (action.type) {
    case CREATE_MY_BIKE_USER_REQUEST:
      return { ...state, loadingCreateBike: true };
    case CREATE_MY_BIKE_USER_SUCCESS:
      return {
        loadingCreateBike: false,
        successCreateBike: true,
        errorCreateBikes: null,
      };
    case CREATE_MY_BIKE_USER_FAILURE:
      return {
        loadingCreateBike: false,
        successCreateBike: false,
        errorCreateBikes: action.payload,
      };
    case CREATE_MY_BIKE_USER_RESET:
      return {
        loadingCreateBike: false,
        successCreateBike: false,
        errorCreateBikes: null,
      };
    default: {
      return state;
    }
  }
};

export const UpdateUserBikeReducer = (
  state = {
    loadingUpdateBike: false,
    successUpdateBike: false,
    errorUpdateBikes: null,
  },
  action: any
) => {
  switch (action.type) {
    case UPDATE_MY_BIKE_USER_REQUEST:
      return { ...state, loadingUpdateBike: true };
    case UPDATE_MY_BIKE_USER_SUCCESS:
      return {
        loadingUpdateBike: false,
        successUpdateBike: true,
        errorUpdateBikes: null,
      };
    case UPDATE_MY_BIKE_USER_FAILURE:
      return {
        loadingUpdateBike: false,
        successUpdateBike: false,
        errorUpdateBikes: action.payload,
      };
    case UPDATE_MY_BIKE_USER_RESET:
      return {
        loadingUpdateBike: false,
        successUpdateBike: false,
        errorUpdateBikes: null,
      };
    default: {
      return state;
    }
  }
};

export const DeleteUserBikeReducer = (
  state = {
    loadingDeleteBike: false,
    successDeleteBike: false,
    errorDeleteBikes: null,
  },
  action: any
) => {
  switch (action.type) {
    case DELETE_MY_BIKE_USER_REQUEST:
      return { ...state, loadingDeleteBike: true };
    case DELETE_MY_BIKE_USER_SUCCESS:
      return {
        loadingDeleteBike: false,
        successDeleteBike: true,
        errorDeleteBikes: null,
      };
    case DELETE_MY_BIKE_USER_FAILURE:
      return {
        loadingDeleteBike: false,
        successDeleteBike: false,
        errorDeleteBikes: action.payload,
      };
    case DELETE_MY_BIKE_USER_RESET:
      return {
        loadingDeleteBike: false,
        successDeleteBike: false,
        errorDeleteBikes: null,
      };
    default: {
      return state;
    }
  }
};

export const SaveProductsReducer = (
  state = {
    loadingSaveProduct: false,
    successSaveProduct: false,
    errorSaveProduct: null,
  },
  action: any
) => {
  switch (action.type) {
    case SAVE_PRODUCT_REQUEST:
      return { ...state, loadingSaveProduct: true, loadingId: action.payload };
    case SAVE_PRODUCT_SUCCESS:
      return {
        loadingSaveProduct: false,
        successSaveProduct: true,
        errorSaveProduct: null,
      };
    case SAVE_PRODUCT_FAILURE:
      return {
        loadingSaveProduct: false,
        successSaveProduct: false,
        errorSaveProduct: action.payload,
      };
    case SAVE_PRODUCT_RESET:
      return {
        loadingSaveProduct: false,
        successSaveProduct: false,
        errorSaveProduct: null,
      };
    default: {
      return state;
    }
  }
};

export const GetSavedProductsReducer = (
  state = {
    loadingGetSavedProducts: false,
    successGetSavedProducts: false,
    favorites: [],
    errorGetSavedProducts: null,
  },
  action: any
) => {
  switch (action.type) {
    case GET_SAVED_PRODUCTS_REQUEST:
      return { ...state, loadingGetSavedProducts: true };
    case GET_SAVED_PRODUCTS_SUCCESS:
      return {
        loadingGetSavedProducts: false,
        successGetSavedProducts: true,
        favorites: action.payload,
        errorGetSavedProducts: null,
      };
    case GET_SAVED_PRODUCTS_FAILURE:
      return {
        loadingGetSavedProducts: false,
        successSaveProduct: false,
        errorSaveProduct: action.payload,
      };
    case GET_SAVED_PRODUCTS_RESET:
      return {
        loadingGetSavedProducts: false,
        successSaveProduct: false,
        errorSaveProduct: null,
      };
    default: {
      return state;
    }
  }
};

export const DeleteSavedProduct = (
  state = {
    loadingDeleteSavedProduct: false,
    successDeleteSavedProduct: false,
    errorDeleteSavedProduct: null,
  },
  action: any
) => {
  switch (action.type) {
    case DELETE_SAVED_PRODUCT_REQUEST:
      return {
        ...state,
        loadingDeleteSavedProduct: true,
        loadingIdDelete: action.payload,
      };
    case DELETE_SAVED_PRODUCT_SUCCESS:
      return {
        loadingDeleteSavedProduct: false,
        successDeleteSavedProduct: true,
        errorDeleteSavedProduct: null,
      };
    case DELETE_SAVED_PRODUCT_FAILURE:
      return {
        loadingDeleteSavedProduct: false,
        successDeleteSavedProduct: false,
        errorDeleteSavedProduct: action.payload,
      };
    case DELETE_SAVED_PRODUCT_RESET:
      return {
        loadingDeleteSavedProduct: false,
        successDeleteSavedProduct: false,
        errorDeleteSavedProduct: null,
      };
    default: {
      return state;
    }
  }
};

export const SaveAllyInfoReducer = (
  state = {
    loadingSaveAllyInfo: false,
    successSaveAllyInfo: false,
    errorSaveAllyInfo: null,
  },
  action: any
) => {
  switch (action.type) {
    case SAVE_ALLY_INFO_REQUEST:
      return { ...state, loadingSaveAllyInfo: true };
    case SAVE_ALLY_INFO_SUCCESS:
      return {
        loadingSaveAllyInfo: false,
        successSaveAllyInfo: true,
        errorSaveAllyInfo: null,
      };
    case SAVE_ALLY_INFO_FAILURE:
      return {
        loadingSaveAllyInfo: false,
        successSaveAllyInfo: false,
        errorSaveAllyInfo: action.payload,
      };
    case SAVE_ALLY_INFO_RESET:
      return {
        loadingSaveAllyInfo: false,
        successSaveAllyInfo: false,
        errorSaveAllyInfo: null,
      };
    default: {
      return state;
    }
  }
};
